/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Banner scrolling class
        window.requestAnimationFrame(Sage.common.banner_section_scrolling_class);

        // Social sharing
        HavenK.social.bind_social_sharers();

        $( 'body' )
          .on( 'focusin', '.form-control', function(event) {
            event.preventDefault();

            $( this ).closest( '.form-group' ).addClass( 'form-group-focused' );
          })

          .on( 'focusout', '.form-control', function(event) {
            event.preventDefault();

            $( this ).closest( '.form-group' ).removeClass( 'form-group-focused' );
          })

          .on( 'click', '.control-label', function(event) {
            event.preventDefault();

            var $this = $( this );

            if( ! $this.closest( '.form-group' ).hasClass( 'form-group-focused' ) ) {
              $this.closest( '.form-group' ).find( '.form-control' ).focus();
            }
          })

          .on( 'change', '.form-control', function(event) {
            event.preventDefault();

            var $this = $( this );

            if( $this.val() !== "" ) {
              $this.closest( '.form-group' ).addClass( 'form-group-dirty' );
            }
          });

        $( '.form-group' )
          .find( '.form-control' )
            .each(function(i, input) {
              if( $( input ).val() !== "" || $( input ).is( 'select' ) ) {
                $( input ).closest( '.form-group' ).addClass( 'form-group-dirty' );
              }

              $( input ).closest( '.form-group' ).addClass( 'form-group-hover' );
            });

        $('body')
          .on('click', '[data-toggle="aside"]', function(event) {
            event.preventDefault();

            var $target = $($(this).data('target'));
            var $_page = $('.wrap');
            var $transformTargets = $('.wrap, .banner__section');

            if($target.length > 0) {
              if ($target.hasClass('main-menu')) {
                if( $('body').hasClass( 'aside-menu-opened' ) || $('body').hasClass( 'aside-menu-opened--right' ) ) {
                  $target.applyTransform( 0 );
                  $transformTargets.applyTransform( 0 );

                  $('body').removeClass('aside-menu-opened').removeClass('aside-menu-opened--right');
                  $( '.aside-menu' ).removeClass( 'aside-menu--opened' );
                }
                else {
                  $target.addClass('aside-menu--opened');

                  var pageWidth = $transformTargets.outerWidth();
                  var containerWidth = $( '.container' ).outerWidth();
                  var sideWidth = ( pageWidth - containerWidth ) / 2;
                  var moduleWidth = $target.find('.aside-menu__nav').outerWidth();
                  var neededWidth = Math.floor( moduleWidth - sideWidth );

                  if( sideWidth >= moduleWidth ) {
                    $target.applyTransform( 0 - Math.ceil( sideWidth + 10 ) );

                    $('body').addClass( 'aside-menu-opened' );
                    $('body').addClass( 'aside-menu-opened--right' );
                  }
                  else {
                    if( pageWidth < ( moduleWidth + 45) ) {
                      $target.find('.aside-menu__nav').css( 'max-width', pageWidth - 45 );

                      // Apply transform
                      $target.applyTransform( 0 - pageWidth + 45 );
                      $transformTargets.applyTransform( 0 - pageWidth + 45 );
                    }
                    else {
                      $target.applyTransform( -13 - moduleWidth );
                      $transformTargets.applyTransform( 0 - neededWidth );
                    }

                    $('body').addClass( 'aside-menu-opened' );
                  }
                }
              }
            }
          });

        if( $('.section__map').length > 0 ) {
          HavenK.maps.init( '.section__map' );
        }

        $( '.page-header--fixed' )
          .stick_in_parent({
            offset_top : $('body').hasClass('admin-bar') ? $('#wpadminbar').outerHeight() : 0
          })
          .on('click', 'a', function(event) {
            event.preventDefault();

            var $target = $($(this).attr('href'));

            $('html, body').stop(true, true).animate({
              scrollTop: $target.offset().top - ( $('body').hasClass('admin-bar') ? $('#wpadminbar').outerHeight() : 0 ) - 40
            }, 700 );
          });


        $('.teaser-banner')
          .on('click', '.teaser-banner__language-active', function (e) {
            e.preventDefault();

            $(this).parent().toggleClass('teaser-banner__language--opened');
          });

        $('.teaser-popup')
          .on('click', '.teaser-popup__close', function (e) {
            e.preventDefault();

            $(this).closest('.teaser-popup').toggleClass('teaser-popup--closed');
            window.localStorage.setItem('teaserPopup', (new Date()).getTime());
          })
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        window.setTimeout(function () {
          if ($('.teaser-popup').length == 0) {
            return;
          }

          if (window.localStorage) {
            var visitedTime = window.localStorage.getItem('teaserPopup');

            if (visitedTime == null || (new Date()).getTime() > parseInt(visitedTime)) {
              $('.teaser-popup').removeClass('teaser-popup--closed');

              window.localStorage.setItem('teaserPopup', (new Date()).getTime() + (60 * 60 * 24 * 7));
            }
          } else {
            $('.teaser-popup').removeClass('teaser-popup--closed');
          }

        }, window.sage.popup_delay);

        if ($('.owl-carousel').length > 0) {
          $('.owl-carousel')
            .not('.fullpage__gallery')
            .not('.clientvisit-gallery')
            .not('.teaser-carousel__owl')
            .owlCarousel({
              items: 1,
              URLhashListener: true,
              onInitialized: function() {
                $('.owl-carousel')
                  .imagesLoaded()
                  .done(function() {
                    $.fn.matchHeight._update();
                  })
              },
              onChange: function(event) {
                var SageLazyLoad = new LazyLoad({
                  elements_selector: ".image--lazy",
                });
              },
              onResized: function(event) {
                $.fn.matchHeight._update();
              },
              onChanged: function(event) {
                $(event.target).closest('.page__section')
                  .find('.content__navigation')
                    .find('li.active').removeClass('active');

                $(event.target).closest('.page__section')
                  .find('.content__navigation')
                    .find('li').eq(event.item.index).addClass('active');
              }
            });

          $('.clientvisit-gallery, .teaser-carousel__owl')
          .each(function (i, elem) {
            if ($(elem).data('speed')) {
              var speed = $(elem).data('speed');
            } else {
              var speed = 5000;
            }

            $(elem)
              .owlCarousel({
                animateOut: 'fadeOut',
                items: 1,
                nav: true,
                URLhashListener: false,
                autoplay: true,
                autoplayTimeout: speed,
                autoplayHoverPause: false,
                dots: true,
                loop: true,
              });
          })
        }
        else {
          if (! $('.body').hasClass('home')) {
            var SageLazyLoad = new LazyLoad({
              elements_selector: ".image--lazy"
            });
          }
        }

        $( 'body' ).imagesLoaded()
          .done( function() {
            $.fn.matchHeight._update();
          });

        $(window)
          .on('resize', function() {
            $.fn.matchHeight._update();
          });


        // Modal bindings
        HavenK.modals.binds();

        // Listen for back button
        $('body')
          .on('click', '.link__back', function(event) {
            event.preventDefault();

            window.history.back();
          });

        // Check for hashbang
        HavenK.hashbang.check();

        // Listen for url hash changes
        HavenK.hashbang.listen();

        $( '.gallery .gallery-item a' ).fancybox();

        $('.teaser-calendly-button')
          .on('click', function(event) {
            event.preventDefault();

            Calendly.showPopupWidget('https://calendly.com/risto-hk/lesta-10');
          })

        $('.teaser-banner')
          .on('click', '.banner__send-enquiry a', function (event) {
            event.preventDefault();

            $('#form-send-inquiry').modal('show');

            gtag('event', 'Saada päring', {'event_category':'Nupu klikkimine'});
          })

          .on('click', '.teaser-banner__menu-trigger', function (event) {
            event.preventDefault();

            $('body').toggleClass('aside-menu-opened');
          })

        // Google Analytics event tracking
        $('a[href^="mailto:"]').bind({
          copy : function(){
            gtag('event', 'e-mail', {'event_category': 'E-mail', 'event_label': 'E-maili kopeerimine'});
          },
          cut : function(){
            gtag('event', 'e-mail', {'event_category': 'E-mail', 'event_label': 'E-maili kopeerimine'});
          },
          click : function(){
            gtag('event', 'e-mail', {'event_category': 'E-mail', 'event_label': 'E-maili klikkimine'});
          }
        });

        $('a[href^="tel:"]').bind({
          copy : function(){
            gtag('event', 'phone', {'event_category': 'Telefon', 'event_label': 'Numbri kopeerimine'});
          },
          cut : function(){
            gtag('event', 'phone', {'event_category': 'Telefon', 'event_label': 'Numbri kopeerimine'});
          },
          click : function(){
            gtag('event', 'phone', {'event_category': 'Telefon', 'event_label': 'Numbri klikkimine'});
          }
        });
      },

      banner_section_scrolling_class: function() {
        var scrollTop = $(window).scrollTop();
        var scrollDirection = scrollTop < (window.lastScrollTop ? window.lastScrollTop : 0) ? 'down' : 'up';

        if (scrollTop > 100) {
          $('.teaser-banner').addClass('teaser-banner--scrolling');
        } else {
          $('.teaser-banner').removeClass('teaser-banner--scrolling');
        }

        if (scrollTop != window.lastScrollTop) {
          if (scrollTop <= 100 || scrollDirection == 'down') {
            $('.teaser-banner').removeClass('teaser-banner--hidden');
          } else if (scrollTop > 100 && ! $('.teaser-banner').hasClass('teaser-banner--hidden') && scrollDirection == 'up') {
            $('.teaser-banner').addClass('teaser-banner--hidden');
          }
        }

        window.lastScrollTop = $(window).scrollTop();
        window.requestAnimationFrame(Sage.common.banner_section_scrolling_class);
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'page_template_template_prices_and_plans': {
      init: function() {
        HavenK.floorplans.house_map_binding();
      },
      finalize: function() {

        $( '.area-plans__image' )
          .on('init.mapify', function(event) {
            $('.mapify-holder')
              .find('.building-floorplan')
              .each(function(i, elem) {
                var $zone     = $(elem);

                if(! $zone.data('building-slug')) {
                  return;
                }

                var $imgHolder = $('.section__area-plans').find('.mapify-imgHolder');

                if ($imgHolder.find('.' + $zone.data('popover_class')).length == 0) {
                  var $template = $('#tmpl-area-tooltip');
                  Mustache.parse($template.html());

                  var template = Mustache.render($template.html(), $zone.data());

                  $('.section__area-plans')
                    .find('.mapify-imgHolder')
                      .append(template);
                }
                else {

                }
              });
          })

          .mapify();

        $('.section__area-plans')
          .on('click', '.building-floorplan', function(event) {
            var $this    = $(this);
            var location = $this.data('building-slug');

            if (location !== '') {
              window.location.hash = HavenK.floorplans.get_house_url(location, 1);
            }
          })

          .on('click', '.apartment__floormap', function(event) {
            event.preventDefault();

            var $this = $(this);

            if($this.data('building-slug')) {
              window.location.hash = HavenK.floorplans.get_house_url($this.data('building-slug'), $this.data('floor'));
            }
          })

          .on('mouseenter', '.building-floorplan', function(event) {
            var $zone     = $(this);

            if(! $zone.data('popover_class')) {
              return;
            }

           $('.section__area-plans').find('.mapify-imgHolder').find('.' + $zone.data('popover_class')).addClass('map-popover--visible');
          })

          .on('mouseleave', '.building-floorplan', function(event) {
            $('.section__area-plans')
              .find('.mapify-imgHolder')
                .find('.map-popover--visible').removeClass('map-popover--visible');
          });
      }
    },

    'page_template_template_pricelist': {
      init: function() {
        HavenK.areaplans.fetch_apartments();
        HavenK.areaplans.init_filters();

        $('.table--pricelist')
          .on('click', 'td, th', function(event) {
            event.preventDefault();

            if ($(this).closest('tr:not(.apartment__row--sold)').data('url')) {
              window.location = $(this).closest('tr').data('url');
            }
          });
      },
      finalize: function() {
      }
    },

    'single_oi_apartments': {
      init: function() {
        HavenK.floorplans.house_map_binding();

        $('#apartment-floor-navigation')
          .on('change', function(event) {
            $('.apartment-plan__image--active').removeClass('apartment-plan__image--active');

            $('.apartment-plan__image-wrap')
              .find('.apartment-plan__image:nth-child('+ $(this).val() +')')
                .addClass('apartment-plan__image--active');

            $.fn.matchHeight._update();
          });
      }
    },

    'single_post' : {
      init: function() {
        $('.post__sharing')
          .stick_in_parent({
            offset_top : ( $('body').hasClass('admin-bar') ? $('#wpadminbar').outerHeight() : 0 ) + 30,
            spacer: false
          })
            .on('sticky_kit:stick', function() {
              $(this).css('left', $('.section__post').find('.entry-content').offset().left);
            })
            .on('sticky_kit:unstick', function() {
              $(this).css('left', '');
            })
            .on('sticky_kit:bottom', function() {
              $(this).css('left', '');
              $(this).addClass('post__sharing--bottomed');
            })
            .on('sticky_kit:unbottom', function() {
              $(this).css('left', $('.section__post').find('.entry-content').offset().left);
              $(this).removeClass('post__sharing--bottomed');
            });
      }
    },

    'front_page': {
      init: function() {
        var that = this;

        $('.teaser-apartments')
          .on('click', '.teaser-apartments__clear:not(.teaser-apartments__clear--hidden)', function (e) {
            e.preventDefault();

            $('.teaser-apartments__filter').find('select').val('');
            $('.teaser-apartments__filter').find('input').prop('checked', true);
            $(this).addClass('teaser-apartments__clear--hidden');

            that.compileFilters();
          })
          .on('click', '.teaser-apartments__load-more', function (e) {
            e.preventDefault();

            var $hidden = $('.teaser-apartment--hidden');

            if ($hidden.length > 5) {
              $hidden.slice(0, 5).removeClass('teaser-apartment--hidden');
            } else if ($hidden.length > 0) {
              $hidden.removeClass('teaser-apartment--hidden');
              $('.teaser-apartments__load-more').addClass('teaser-apartments__load-more--disabled');
            } else {
              $('.teaser-apartments__load-more').addClass('teaser-apartments__load-more--disabled');
            }
          })

        $('.teaser-apartments__filter')
          .on('change', 'select, input', function (e) {
            $(this).data('dirty', true);
            that.compileFilters();
            that.showClearButton();
          });

        $(window)
          .scroll(function (event) {
            that.showHideBookingButton();
          });

        this.showHideBookingButton();

        $('.teaser-footer')
          .on('submit', '.teaser-footer__newsletter', function (e) {
            e.preventDefault();

            var $form = $(this);
            var form = $form.get(0);
            var $input = $form.find('input[type="email"]');
            var $feedback = $form.find('.invalid-feedback');

            HavenK.loading.show('dark', '.teaser-footer__newsletter', 'absolute');

            $.post(
              sage.ajaxurl,
              {
                action: 'oi_teaser_newsletter',
                user_email: $form.find('input[type="email"]').val(),
                mc_list_id: $form.find('input[name="mc_list_id"]').val(),
                lang: window.sage.lang,
                security: window.sage.security_nonce
              },
              function (response) {
                var $feedback = null;

                if (response.success === true) {
                  $input.addClass('is-valid').removeClass('is-invalid');
                  $input.get(0).setCustomValidity('');

                  $form.find('.invalid-feedback').removeClass('invalid-feedback--visible');
                  $form.find('.input-group').hide();
                  $form.find('.valid-feedback').addClass('valid-feedback--visible').text(response.data.message);

                  gtag('event', 'Liitu', {'event_category': 'Uudiskiri'});
                }
                else {
                  $input.removeClass('is-valid').addClass('is-invalid');
                  $input.get(0).setCustomValidity(response.data.message);

                  $form.find('.valid-feedback').removeClass('valid-feedback--visible')
                  $form.find('.invalid-feedback').addClass('invalid-feedback--visible').text(response.data.message);
                }

                $form.addClass('was-validated');
                HavenK.loading.hide();
              }
            );
          });

        $('.teaser-image-shadow').imagesLoaded(function() {
          window.imageRellax = new Rellax('.teaser-image-shadow', {
            speed: -2,
            vertical: true,
            horizontal: false,
            center: true,
            breakpoints: [768, 1024, 1280, 1440]
          });
        })

        $('.teaser-booking-button')
          .on('click', function (event) {
            event.preventDefault();

            var $apartments = $('#section-apartments');

            $('html, body').stop(true, true).animate({
              scrollTop: $apartments.offset().top - ( $('body').hasClass('admin-bar') ? $('#wpadminbar').outerHeight() : 0 )
            }, 700 );
          });
      },

      finalize: function() {
        $('.owl-carousel').trigger('stop.owl.autoplay');

        $('.teaser-video__placeholder')
          .on('click', function (event) {
            event.preventDefault();

            $('.teaser-video__video').addClass('teaser-video__video--visible');
          })

          $('.clientvisit-hero__video')
            .on('click', function (event) {
              event.preventDefault();

              $('.clientvisit-hero__video').addClass('clientvisit-hero__video--visible');
            })

        $(window)
          .on('sage-maybe-load-videos', function () {
            var $containers = $('.teaser-video:in-viewport, .clientvisit-hero:in-viewport')

            if ($containers.length > 0) {
              if (window.imageRellax) {
                window.imageRellax.refresh();
              }
            }

            $containers.find('iframe').each(function (i, elem) {
                var $video = $(elem)

                if (! $video.attr('src')) {
                  $video.attr('src', $video.data('src')).removeAttr('data-src');
                }
              });
          })

        $(window)
          .scroll(function () {
            $(window).trigger('sage-maybe-load-videos');

            $('.teaser-carousel')
              .each(function (i, elem) {
                if ($(elem).is(':in-viewport')) {
                  if (! $(elem).data('autoplay-triggered')) {
                    $(elem).data('autoplay-triggered', true).find('.owl-carousel').trigger('play.owl.autoplay', [$(elem).data('speed'), $(elem).data('speed')]);

                    if (window.imageRellax) {
                      window.imageRellax.refresh();
                    }
                  }
                } else {
                  if ($(elem).data('autoplay-triggered') != false) {
                    $(elem).data('autoplay-triggered', false).find('.owl-carousel').trigger('stop.owl.autoplay');
                  }
                }
              })
          })

        window.setTimeout(function () {
          $(window).trigger('resize');
        }, 500);

        $(window).trigger('sage-maybe-load-videos');
      },

      showHideBookingButton: function() {
        var scrollTop = $(window).scrollTop();
        var $btn = $('.teaser-booking-button');

        if (scrollTop + $(window).height() > $(document).height() - ($('.teaser-contact').outerHeight() + $('.teaser-footer').outerHeight() + 40)) {
          $btn.addClass('teaser-booking-button--hidden').addClass('teaser-booking-button--end');
        }
        else if (scrollTop > 50 && $btn.hasClass('teaser-booking-button--hidden')) {
          $btn.removeClass('teaser-booking-button--hidden').removeClass('teaser-booking-button--end');
        } else if (scrollTop <= 50) {
          $btn.addClass('teaser-booking-button--hidden');
        }
      },

      showClearButton: function() {
        $('.teaser-apartments__clear').removeClass('teaser-apartments__clear--hidden');
      },

      compileFilters: function() {
        var filters = [];

        $('.teaser-apartments__filter')
          .not('.teaser-apartments__filter--clear')
          .each(function (i, filter) {
            var $filter = $(filter).find('input, select');

            filters.push({
              name: $filter.attr('name'),
              value: $filter.is('input') ? $filter.prop('checked') : $filter.val()
            });
          });

        this.searchApartments(filters);
      },

      searchApartments: function (filters) {
        HavenK.loading.show('dark', '.teaser-apartments', 'absolute');

        $.get(
          sage.ajaxurl,
          {
            action: 'oi_get_teaser_apartments',
            filters: filters,
            lang: window.sage.lang,
            security: window.sage.security_nonce
          },
          function(response) {
            if (response.success === true) {
              $('.teaser-apartments__wrap').html(response.data);

              if ($('.teaser-apartment--hidden').length > 0) {
                $('.teaser-apartments__load-more').removeClass('teaser-apartments__load-more--disabled');
              } else {
                $('.teaser-apartments__load-more').addClass('teaser-apartments__load-more--disabled');
              }
            }

            HavenK.loading.hide();
          }
        );
      },
    },
  };

  var HavenK = {
    'floorplans': {
      get_house_url: function(slug, floor) {
        return sage.houses_url_slug + '/' + slug + '/' + floor;
      },

      house_map_binding: function() {
        $( '.house-plan__inner-wrap' )
          .on('click', '.house', function(event) {
            event.preventDefault();

            var $this    = $(this);
            var $inner   = $this.closest('.house-plan__inner-wrap');
            var house    = $this.data('house');
            var location = $inner.data('slug-' + house);

            if (location !== '') {
              window.location.hash = HavenK.floorplans.get_house_url(location, 1);
            }
          });
      },

      open: function(house_slug, floor, replace_modal) {
        if (floor !== window.sage.parking_url_slug) {
          floor = typeof floor === 'undefined' ? 1 : parseInt(floor);
        } else {
          floor = 'parking';
        }

        replace_modal = typeof replace_modal === 'undefined' ? false : replace_modal;

        if (replace_modal === true) {
          HavenK.loading.show('dark', '.section__floorplan');
        }
        else {
          HavenK.loading.show();
        }

        $.get(
          sage.ajaxurl,
          {
            action: 'oi_get_floorplan_modal',
            house: house_slug,
            floor: floor,
            lang: sage.lang,
            security: sage.security_nonce
          },
          function(response) {
            if (response.success === true) {
              if (replace_modal === true) {
                $('#floorplan-modal').replaceWith( $(response.data.modal) );
              }
              else {
                $('body').append($(response.data.modal));
              }

              $('#floorplan-modal')

                .on('show.bs.modal', function(e) {
                  $.fn.matchHeight._apply($('.floorplan__content, .floorplan__navigation'));

                  $('.floorplan__image')
                    .on('init.mapify', function(event) {
                      $('#floorplan-modal').find('area').trigger('mouseenter').trigger('touchstart').trigger('touchmove').trigger('touchend');
                    })
                    .on('remapZones.mapify', function(event) {
                      $('#floorplan-modal').find('area').trigger('mouseenter').trigger('touchstart').trigger('touchmove').trigger('touchend');
                    })
                    .mapify({
                      popOver: {
                          content: function(zone) {
                            var popzone_txt0 = '';
                            var popzone_txt1 = '';
                            var popzone_txt2 = '';
                            var popzone_txt3 = '';
                            if (zone.attr("data-name")) {
                              var popzone_txt0 = '<h3>'+zone.attr("data-name")+'</h3>';
                            }
                            if (zone.attr("data-parking-txt1")) {
                              var popzone_txt1 = '<p>'+zone.attr("data-parking-txt1")+'</p>';
                            }
                            if (zone.attr("data-parking-txt2")) {
                              var popzone_txt2 = '<p>'+zone.attr("data-parking-txt2")+'</p>';
                            }
                            if (zone.attr("data-parking-txt3")) {
                              var popzone_txt3 = '<p>'+zone.attr("data-parking-txt3")+'</p>';
                            }
                            return popzone_txt0+popzone_txt1+popzone_txt2+popzone_txt3;
                          },
                          delay: 0.3,
                          margin: "15px",
                          height: "auto",
                          width: "260px"
                        }
                    });
                })

                .on('shown.bs.modal', function(e) {

                  HavenK.floorplans.house_map_binding();

                  $('.floorplan-switcher')
                    .on('click', '.floorplan-switcher__button', function(event) {
                      event.preventDefault();

                      var $this = $(this);

                      if ($this.hasClass('floorplan-switcher__button--add')) {
                        window.location.hash = HavenK.floorplans.get_house_url($this.data('building-slug'), $this.data('next-floor'));
                      }
                      else {
                        window.location.hash = HavenK.floorplans.get_house_url($this.data('building-slug'), $this.data('prev-floor'));
                      }
                    });
                })

                .on('hidden.bs.modal', function (e) {
                  $('#floorplan-modal').remove();
                  window.location.hash = '';
                })

                .modal('show');
            }
          },
          'JSON'
        )
        .always(function() {
          HavenK.loading.hide();
        });
      }
    },

    'areaplans' : {
      request: null,

      fetch_apartments: function() {
        var $table = $('.table--pricelist');

        if (this.request !== null) {
          this.request.abort();
        }

        HavenK.loading.show('dark', '.section__pricelist', 'absolute');

        this.request = $.get(
          sage.ajaxurl,
          {
            action: 'oi_get_apartments',
            filters: $('#apartments-filter').serialize(),
            lang: sage.lang
          },
          function(response) {
            Mustache.parse(response.data.template);

            var table_body = Mustache.render(response.data.template, {apartments: response.data.apartments});

            $table.find('tbody')
              .empty()
              .html(table_body);

            HavenK.loading.hide();
            var resort = true;
            $table.trigger("update", [resort]);
          },
          'JSON'
        );
      },

      init_filters: function() {
        var price_min       = $('.price-slider').data('min');
        var price_max       = $('.price-slider').data('max');

        //var price_thirty_from_min = Math.ceil( ( price_min / 100 * 130 ) / 10000 ) * 10000;
        //var price_ten_from_max    = Math.ceil( ( price_max / 100 * 90 ) / 10000 ) * 10000;

        HavenK.sliders.init('.price-slider', {
          min: price_min,
          max: price_max,
          start: [ price_min, price_max ],
          step: 10000
        });

        var size_min       = $('.size-slider').data('min');
        var size_max       = $('.size-slider').data('max');

        //var size_thirty_from_min = Math.ceil( ( size_min / 100 * 130 ) / 10 ) * 10;
        //var size_ten_from_max    = Math.ceil( ( size_max / 100 * 90 ) / 10 ) * 10;

        HavenK.sliders.init('.size-slider', {
          min: size_min,
          max: size_max,
          start: [ size_min, size_max ],
          step: 1
        });

        $('#apartments-filter')
          .on('change', 'select, input', function(event) {
            HavenK.areaplans.fetch_apartments();
          });
      }
    },

    'modals': {
      binds: function() {
        $(window).keydown(function(event) {
          if(event.key === "Escape") {
            $('.modal').modal('hide');
          }
        });
      }
    },

    'hashbang': {
      listen: function() {
        $(window)
          .on('hashchange', function(event) {
            HavenK.hashbang.check();
          });
      },

      check: function() {
        var base_url_hash = sage.houses_url_slug;
        var url_hash      = window.location.href.split('#');

        if ( url_hash[1] == sage.parking_url_slug ) {
          HavenK.floorplans.open('', 'parking', $('#floorplan-modal').length > 0);
        }
        else if ( url_hash !== 'modal-closed' && url_hash !== '' && url_hash.length > 1 ) {
          var url_hash_path = url_hash[1].split('/');

          if ( url_hash_path && ( url_hash_path[0] === base_url_hash || ( url_hash_path && url_hash_path[0] === '!' + base_url_hash ) ) ) {
            var house_slug  = url_hash_path[1];
            var house_floor = url_hash_path[2];

            HavenK.floorplans.open(house_slug, house_floor, $('#floorplan-modal').length > 0);
          }
        }
        else if( window.location.hash == '' ) {
          if( $('#floorplan-modal').length > 0 ) {
            $('#floorplan-modal').modal('hide');
          }
        }
      }
    },

    'maps' : {
      script_fetched : false,
      location_pins : [],
      location_map : null,
      open_infowindow : null,

      init: function(target) {
        if (this.script_fetched === false) {
          $.getScript(
            '//maps.googleapis.com/maps/api/js?key=' + sage.gmaps_api_key,
            function() {
              HavenK.maps.create(target);
            }
          );
        }
        else {
          HavenK.maps.create(target);
        }
      },

      create: function(elem) {
        var $map = $(elem);
        var args = {
          zoom                   : 14,
          center                 : new google.maps.LatLng(0, 0),
          mapTypeId              : google.maps.MapTypeId.ROADMAP,
          disableDefaultUI       : false,
          disableDoubleClickZoom : false,
          scrollwheel            : false,

          // Snazzy Maps: https://snazzymaps.com/style/134/light-dream
          styles    : [{"featureType":"landscape","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"road.highway","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]},{"featureType":"poi","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]}]
        };

        var map  = new google.maps.Map($map[0], args);

        if ($map.data('lat') && $map.data('lng')) {
          this.setCenter(map, $map.data('lat'), $map.data('lng'));
          this.addMarker(map, $map.data('lat'), $map.data('lng'), $map.data('content'));
        }
        else if ($map.data('location-pins')) {
          this.createLocationPins(map, $map.data('location-pins'));
          this.mapNavigation(map);
        }

        $('body')
          .on('click', '.map-control__zoom-in', function(event) {
            event.preventDefault();

            map.setZoom( map.getZoom() + 1 );
          })
          .on('click', '.map-control__zoom-out', function(event) {
            event.preventDefault();

            map.setZoom( map.getZoom() - 1 );
          });
      },

      createLocationPins: function(map, pins) {
        var bounds = new google.maps.LatLngBounds();

        for (var i = 0; i < pins.length; i++) {
          var coords = new google.maps.LatLng(pins[i].location_on_map.lat, pins[i].location_on_map.lng);

          var location_marker = this.addMarker(map, pins[i].location_on_map.lat, pins[i].location_on_map.lng, pins[i].name, pins[i].icon, {category: pins[i].category});

          if (pins[i].category == 'haven') {
            location_marker.setZIndex(1000);
          }

          this.location_pins.push(location_marker);

          bounds.extend(coords);
        }

        map.fitBounds(bounds);
      },

      setCenter: function(map, lat, lng) {
        var latLng = new google.maps.LatLng(lat, lng);

        map.setCenter(latLng);
      },

      addMarker: function(map, lat, lng, content, icon, extra_data) {
        var latLng = new google.maps.LatLng(lat, lng);
        var marker = new google.maps.Marker({
          position: latLng,
          map     : map
        });

        if (typeof icon !== 'undefined') {
          marker.setIcon(icon);
        }
        else {
          marker.setIcon({
            path: 'M12,23 C12.0359943,23 4,13.418278 4,9 C4,4.581722 7.581722,1 12,1 C16.418278,1 20,4.581722 20,9 C20,13.418278 11.9640057,23 12,23 Z M12,12 C13.6568542,12 15,10.6568542 15,9 C15,7.34314575 13.6568542,6 12,6 C10.3431458,6 9,7.34314575 9,9 C9,10.6568542 10.3431458,12 12,12 Z',
            fillColor: '#0055a6',
            fillOpacity: 1,
            strokeColor: 'transparent'
          });
        }

        if (typeof extra_data !== 'undefined') {
          for( var k in extra_data ) {
            marker[k] = extra_data[k];
          }
        }

        if (typeof content !== 'undefined' && content) {
          var infoWindow = new google.maps.InfoWindow({
            content: content
          });

          marker.addListener('click', function() {
            infoWindow.open(map, marker);

            if (HavenK.maps.open_infowindow != null) {
              HavenK.maps.open_infowindow.close();
            }

            HavenK.maps.open_infowindow = infoWindow;
          });

          if ($('body').hasClass('page-template-template-sales-information')) {
            infoWindow.open(map, marker);
          }
        }

        return marker;
      },

      mapNavigation: function(map) {
        $('.map-navigation')
          .on('click', 'li', function(event) {
            event.preventDefault();

            var $this = $(this);
            var category = $this.data('category');
            var $nav = $this.closest('.map-navigation');

            if (category == '' || ! category) {
              $nav.find('.active').removeClass('active');

              $this.addClass('active');
            }
            else {
              if ($nav.find('.map-navigation__all').hasClass('active')) {
                $nav.find('.map-navigation__all').removeClass('active');
              }

              if ($this.hasClass('active')) {
                $this.removeClass('active');

                if ($nav.find('.active').length == 0) {
                  $nav.find('.map-navigation__all').trigger('click');
                }
              }
              else {
                $this.addClass('active');
              }
            }

            if ($nav.find('.map-navigation__all').hasClass('active')) {
              for (var i = 0; i < HavenK.maps.location_pins.length; i++) {
                HavenK.maps.location_pins[i].setMap(map);
              }
            }
            else {
              for (var i = 0; i < HavenK.maps.location_pins.length; i++) {
                if (HavenK.maps.location_pins[i].category == 'haven') {
                  continue;
                }

                if ($nav.find('li[data-category="'+HavenK.maps.location_pins[i].category+'"]').hasClass('active')) {
                  if (HavenK.maps.location_pins[i].getMap() === null) {
                    HavenK.maps.location_pins[i].setMap(map);
                  }
                }
                else {
                  HavenK.maps.location_pins[i].setMap(null);
                }
              }
            }

          });
      }
    },

    'sliders': {
      init: function(elem, config) {
        var sliderElem = $(elem).get(0);

        noUiSlider.create(sliderElem, {
          connect: false,
          start: config.start,
          step: config.step,
          range: {
            'min': config.min,
            'max': config.max
          }
        });

        var $lowText = $(sliderElem).closest('.form-group-rangeslider').find('.rangeslider-text--lower');
        var $highText = $(sliderElem).closest('.form-group-rangeslider').find('.rangeslider-text--higher');

        var $lowValue = $(sliderElem).closest('.form-group-rangeslider').find('.rangeslider-value--lower');
        var $highValue = $(sliderElem).closest('.form-group-rangeslider').find('.rangeslider-value--higher');

        sliderElem.noUiSlider.on('change', function(values, handle, unencoded, isTap, positions) {
          $lowValue.val(parseInt(values[0]));
          $highValue.val(parseInt(values[1])).trigger('change');
        });

        sliderElem.noUiSlider.on('update', function(values, handle, unencoded, isTap, positions) {
          $lowText.html(parseInt(values[0]) + $lowText.data('append'));
          $highText.html(parseInt(values[1]) + $highText.data('append'));
        });
      }
    },

    'loading' : {
      show: function(theme, target, position_type) {
        theme = typeof theme == 'undefined' ? 'dark' : theme;
        target = typeof target == 'undefined' ? 'body' : target;

        if (typeof position_type === 'undefined' || position_type === false) {
          position_type = 'fixed';
        }
        else {
          position_type = 'absolute';
        }

        $(target).addClass('loading').addClass('loading--' + position_type);
        $('<div />').addClass('loader').addClass('loader--' + theme).appendTo(target);
      },

      hide: function() {
        $('.loader').fadeOut('fast', function() {
          $(this).remove();
          $('.loading').removeClass('loading').removeClass('loading--absolute').removeClass('loading--fixed');
        });
      }
    },

    social: {
      social_window: null,

      bind_social_sharers: function() {
        $('.post__sharing')
          .on('click', '[data-social-network]', function(event) {
            event.preventDefault();

            var $this        = $(this);
            var network      = $this.data('social-network');
            var network_name = $this.data('social-name');
            var href         = $this.attr('href');

            var target       = null;

            if (network === 'facebook') {
              target = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(href);
            }
            else if (network === 'twitter') {
              target = 'https://twitter.com/home?status=' + encodeURIComponent(href);
            }
            else if (network === 'googleplus') {
              target = 'https://plus.google.com/share?url=' + encodeURIComponent(href);
            }
            else if (network === 'linkedin') {
              target = 'https://www.linkedin.com/shareArticle?url=' + encodeURIComponent(href);
            }
            else if (network === 'vk') {
              target = 'https://vk.com/share.php?url=' + encodeURIComponent(href);
            }

            if (null !== target) {
              if (HavenK.social.social_window === null || HavenK.social.social_window.closed) {
                HavenK.social.social_window = window.open(target, network_name, 'resizable,scrollbars,status,width=600,height=450');
              }
              else {
                HavenK.social.social_window.focus();
              }
            }
          });
      }
    }
  };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $.fn.applyTransform = function(width) {
    this.css({
      'webkitTransform' : 'translate('+ width +'px, 0)',
      'MozTransform' : 'translate('+ width +'px, 0)',
      'msTransform' : 'translate('+ width +'px, 0)',
      '-ms-transform' : 'translate('+ width +'px, 0)',
      'OTransform' : 'translate('+ width +'px, 0)',
      'transform' : 'translate('+ width +'px, 0)'
    });

    return this;
  };

  var SortingTable = {
    init: function() {
      this.table = $('.tablesorter');

      if(!this.table.length) return;

      this.sort();

    },
    getHeaders: function() {
      var headers = {};
      $.each(this.table.find('thead th'), function(e) {
        headers[e] = {
          sorter: $(this).data('sort') === 1 ? true : false
        };
      });
      return headers;
    },
    sort: function() {
      this.table.tablesorter({
        headers: SortingTable.getHeaders(),
        widthFixed: true,
        widgets: ['stickyHeaders'],
        widgetOptions: {
          stickyHeaders_offset: $(window).width() < 1024 ? 135 : 0
        }
      });

    }
  }

  window.addEventListener('load', function() {
    setTimeout(function() {
      SortingTable.init();
    }, 1000);
  });

  // Track header modal form submission
  $(document).on('nfFormReady', function() {
    nfRadio.channel('forms').on('submit:response', function(form) {
      var modal_shorcode = window.sage.modal_shortcode;
      var form_id = modal_shorcode.match(/\d+/);
      if (form.data.form_id == form_id[0]) {
        ga('event', 'Edukalt saadetud', {'event_category': 'Saada päring'});
      }
    });
  });

})(jQuery); // Fully reference jQuery after this point.
